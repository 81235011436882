import React from "react";
import _ from "lodash";
import { Button, Col, Row } from "react-bootstrap";
import { DeleteOutlined } from "@ant-design/icons";

import { currency, qtyToCurrency } from "utils/formatter";
import EditableText from "./EditableText";

const Item = ({ data, width, setItem, removeItem, setData }) => {
  return data.items.map((item, i) => {
    if (width < 768) {
      return (
        <tr key={i} style={{ backgroundColor: "#e9ebf0" }}>
          <td className="border border-light">
            <Row>
              <Col sm={6} className="mb-2">
                <EditableText
                  setData={setData}
                  value={data.invoice_lang_product}
                  id="invoice_lang_product"
                >
                  <small className="d-inline-block">
                    <strong>{data.invoice_lang_product || "Produk"}</strong>
                  </small>
                </EditableText>
                <EditableText
                  required
                  min={2}
                  setData={setItem}
                  value={item.product_name}
                  id={{ i, name: "product_name" }}
                >
                  <small className="d-inline-block">{item.product_name}</small>
                </EditableText>
              </Col>
              <Col sm={6} className="mb-2">
                <EditableText
                  setData={setData}
                  value={data.invoice_lang_desc}
                  id="invoice_lang_desc"
                >
                  <small className="d-inline-block">
                    <strong>{data.invoice_lang_desc || "Deskripsi"}</strong>
                  </small>
                </EditableText>
                <EditableText
                  setData={setItem}
                  value={item.description}
                  id={{ i, name: "description" }}
                >
                  <small className="d-inline-block">{item.description}</small>
                </EditableText>
              </Col>
              <Col sm={6} className="mb-2">
                <EditableText
                  setData={setData}
                  value={data.invoice_lang_qty}
                  id="invoice_lang_qty"
                >
                  <small className="d-inline-block">
                    <strong>{data.invoice_lang_qty || "Kuantitas"}</strong>
                  </small>
                </EditableText>
                <EditableText
                  type="number"
                  default={1}
                  min={1}
                  setData={setItem}
                  value={item.qty}
                  id={{ i, name: "qty" }}
                >
                  <small className="d-inline-block">
                    {qtyToCurrency(item.qty)}
                  </small>
                </EditableText>
              </Col>
              {data.invoice_show_unit === 1 && (
                <Col sm={6} className="mb-2">
                  <EditableText
                    setData={setData}
                    value={data.invoice_lang_unit}
                    id="invoice_lang_unit"
                  >
                    <small className="d-inline-block">
                      <strong>{data.invoice_lang_unit || "Satuan"}</strong>
                    </small>
                  </EditableText>
                  <EditableText
                    setData={setItem}
                    value={item.unit_name}
                    id={{ i, name: "unit_name" }}
                  >
                    <small className="d-inline-block">{item.unit_name}</small>
                  </EditableText>
                </Col>
              )}
              <Col sm={6} className="mb-2">
                <small className="d-inline-block">
                  <strong>Harga</strong>
                </small>
                <EditableText
                  type="number"
                  default={0}
                  setData={setItem}
                  value={item.price}
                  id={{ i, name: "price" }}
                >
                  <small className="d-inline-block">
                    {currency(_.round(item.price, 2), false, true)}
                  </small>
                </EditableText>
              </Col>
              {data.invoice_show_discount === 1 && (
                <Col sm={6} className="mb-2">
                  <small className="d-inline-block">
                    <strong>Diskon</strong>
                  </small>
                  <EditableText
                    type="number"
                    default={0}
                    min={0}
                    max={100}
                    setData={setItem}
                    value={item.discount_percent}
                    id={{ i, name: "discount_percent" }}
                  >
                    <small className="d-inline-block">
                      {qtyToCurrency(item.discount_percent)}%
                    </small>
                  </EditableText>
                </Col>
              )}
              {data.invoice_show_tax === 1 && (
                <Col sm={6} className="mb-2">
                  <small className="d-block">
                    <strong>Pajak</strong>
                  </small>
                  <EditableText
                    min={2}
                    className="d-inline-block"
                    setData={setItem}
                    value={item.tax_title}
                    id={{ i, name: "tax_title" }}
                  >
                    <small className="d-inline-block">{item.tax_title}</small>
                  </EditableText>
                  <EditableText
                    type="number"
                    default={0}
                    min={0}
                    max={100}
                    className="d-inline-block"
                    setData={setItem}
                    value={item.tax_percent}
                    id={{ i, name: "tax_percent" }}
                  >
                    <small className="d-inline-block">
                      {qtyToCurrency(item.tax_percent)}%
                    </small>
                  </EditableText>
                </Col>
              )}
              <Col sm={6} className="mb-2">
                <EditableText
                  setData={setData}
                  value={data.invoice_lang_amount}
                  id="invoice_lang_amount"
                >
                  <small className="d-inline-block">
                    <strong>{data.invoice_lang_amount || "Jumlah"}</strong>
                  </small>
                </EditableText>
                <EditableText
                  type="number"
                  default={0}
                  className="d-inline-block"
                  setData={setItem}
                  value={item.amount}
                  id={{ i, name: "amount" }}
                >
                  <small>
                    {currency(_.round(item.amount, 2), false, true)}
                  </small>
                </EditableText>
              </Col>
              <Col span={12}>
                {data.items.length !== 1 && (
                  <Button
                    variant="danger"
                    className="d-flex align-items-center mx-auto"
                    onClick={() => removeItem(i)}
                  >
                    <DeleteOutlined />
                  </Button>
                )}
              </Col>
            </Row>
          </td>
        </tr>
      );
    }

    return (
      <tr key={i} style={{ backgroundColor: "#e9ebf0" }}>
        <td className="border border-light">
          <EditableText
            required
            min={2}
            setData={setItem}
            value={item.product_name}
            id={{ i, name: "product_name" }}
          >
            <small className="d-inline-block">{item.product_name}</small>
          </EditableText>
        </td>
        <td className="border border-light">
          <EditableText
            setData={setItem}
            value={item.description}
            id={{ i, name: "description" }}
          >
            <small className="d-inline-block">{item.description}</small>
          </EditableText>
        </td>
        <td className="border border-light text-right">
          <EditableText
            type="number"
            default={1}
            min={1}
            setData={setItem}
            value={item.qty}
            id={{ i, name: "qty" }}
          >
            <small className="d-inline-block">{qtyToCurrency(item.qty)}</small>
          </EditableText>
        </td>
        {data.invoice_show_unit === 1 && (
          <td className="border border-light text-right">
            <EditableText
              setData={setItem}
              value={item.unit_name}
              id={{ i, name: "unit_name" }}
            >
              <small className="d-inline-block">{item.unit_name}</small>
            </EditableText>
          </td>
        )}
        <td className="border border-light text-right">
          <EditableText
            type="number"
            default={0}
            setData={setItem}
            value={item.price}
            id={{ i, name: "price" }}
          >
            <small className="d-inline-block">
              {currency(_.round(item.price, 2), false, true)}
            </small>
          </EditableText>
        </td>
        {data.invoice_show_discount === 1 && (
          <td className="border border-light text-right">
            <EditableText
              type="number"
              default={0}
              min={0}
              max={100}
              setData={setItem}
              value={item.discount_percent}
              id={{ i, name: "discount_percent" }}
            >
              <small className="d-inline-block">
                {qtyToCurrency(item.discount_percent)}%
              </small>
            </EditableText>
          </td>
        )}
        {data.invoice_show_tax === 1 && (
          <td className="border border-light text-right">
            <EditableText
              min={2}
              className="d-inline-block"
              setData={setItem}
              value={item.tax_title}
              id={{ i, name: "tax_title" }}
            >
              <small className="d-inline-block">{item.tax_title}</small>
            </EditableText>
            <EditableText
              type="number"
              default={0}
              min={0}
              max={100}
              className="d-inline-block"
              setData={setItem}
              value={item.tax_percent}
              id={{ i, name: "tax_percent" }}
            >
              <small className="d-inline-block">
                {qtyToCurrency(item.tax_percent)}%
              </small>
            </EditableText>
          </td>
        )}
        <td className="border border-light text-right">
          <EditableText
            type="number"
            default={0}
            className="d-inline-block"
            setData={setItem}
            value={item.amount}
            id={{ i, name: "amount" }}
          >
            <small>{currency(_.round(item.amount, 2), false, true)}</small>
          </EditableText>
        </td>
        <td className="border border-light">
          {data.items.length !== 1 && (
            <Button
              variant="danger"
              className="d-flex align-items-center"
              onClick={() => removeItem(i)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </td>
      </tr>
    );
  });
};

export default Item;
