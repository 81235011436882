import axios from "axios";
import { notify } from "react-notify-bootstrap";

export const generateBaseUri = () => {
  let apiUrl = process.env.REACT_APP_API_URL;

  return apiUrl;
}

export const downloadFileFromResponse = (response) => {
  const downloadLink = document.createElement("a");
  const blob = new Blob(
    [response.data],
    { type: response.headers['content-type'] }
  );
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;

  let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  let matches = filenameRegex.exec(response.headers['content-disposition']);
  if (matches != null && matches[1]) { 
    let filename = matches[1].replace(/['"]/g, '');
    downloadLink.download = filename;
  }
  downloadLink.setAttribute('target', '_blank');
  downloadLink.click();
}

export function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const successHandler = (response) => {
  return response
}

export const bufferToString = (buffer) => {
  return String.fromCharCode.apply(null, Array.from(new Uint8Array(buffer)));
}

export const errorHandler = async (error) => {
  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }

  try {
    const { data } = error.response;
    const file = await bufferToString(data);
    const { message } = JSON.parse(file);

    notify({ text: message || 'Network error', variant: 'danger' });
  } catch (e) {
    const errorMessage = error.response && error.response.data.message ? error.response.data.message : 'Network error';
    notify({ text: errorMessage, variant: 'danger' });
  }

  return Promise.reject(error.response);
}
