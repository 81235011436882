import React from 'react';
import { Button, Image, Modal, Nav } from 'react-bootstrap';
import { LoadingOutlined, PicLeftOutlined } from '@ant-design/icons';

class TemplateModal extends React.Component {
  state = {
    category: null,
  }

  renderTemplate = () => {
    const { isLoading, templates, currentTemplate, setCurrentTemplate } = this.props;
    const { category } = this.state;
    const showCategory = category ? parseInt(category) : parseInt(currentTemplate.cat_id);

    if (isLoading) {
      return (
        <div className="d-flex justify-content-center">
          <LoadingOutlined />
        </div>
      );
    }

    return (
      <>
        <Nav justify variant="tabs" defaultActiveKey={currentTemplate.cat_id}>
          {
            Object.keys(templates.categories).map(catId => (
              <Nav.Item key={catId}>
                <Nav.Link eventKey={catId} onClick={() => this.setState({ category: catId })}>
                  {templates.categories[catId]}
                </Nav.Link>
              </Nav.Item>
            ))
          }
        </Nav>
        <div className="d-flex justify-content-center flex-wrap">
          {
            templates.templates.filter(e => e.cat_id === showCategory).map((template) => (
              <div key={template.id} className="my-2 text-center" style={{ width: '25%' }}>
                <a href={template.img_url} target="_blank" rel="noopener noreferrer">
                  <Image src={template.img_url} className={`template-img ${template.id === currentTemplate.id ? 'active' : ''}`} />
                </a>
                {template.id === currentTemplate.id ? (
                  <small><b>Template saat ini</b></small>
                ) : (
                  <Button variant="primary" size="sm" className="d-flex align-items-center mx-auto" onClick={() => setCurrentTemplate(template)}>
                    <PicLeftOutlined className="mr-2" /> Pilih template ini
                  </Button>
                )}
              </div>
            ))
          }
        </div>
      </>
    );
  }

  close = () => {
    this.setState({ category: null });
    this.props.close();
  }

  render() {
    const { show } = this.props;

    return (
      <Modal show={show} onHide={this.close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Pilih Template Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderTemplate()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={this.close}>
            Batal
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default TemplateModal;
