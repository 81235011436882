import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import reduxThunk from 'redux-thunk';
import reducers from 'redux/reducers';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { errorHandler, generateBaseUri, successHandler } from 'utils/helper';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './global.scss';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
    normalizeDepth: 5,
  });
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sentryReduxEnhancer = Sentry.createReduxEnhancer();
const store = createStore(reducers, composeEnhancers(applyMiddleware(reduxThunk), sentryReduxEnhancer));

axios.defaults.baseURL = generateBaseUri();
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.timeout = 100000;

axios.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error),
)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
