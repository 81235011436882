import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { CheckCircleFilled, PaperClipOutlined } from '@ant-design/icons';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';
import { validateEmail } from 'utils/helper';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const SendEmailForm = ({ onSubmit, ref, data, ref_number, url }) => {
  const [errors, setErrors] = useState({});
  const [sent, setSent] = useState(false);
  const [body, setBody] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(data.body).contentBlocks)))

  const checkValidity = ({ mail_to = 0, subject = 0 }) => {
    let err = {};

    if (mail_to !== 0) {
      if (!mail_to) {
        err.mail_to = 'Mohon masukkan email';
      }
      if (mail_to && !validateEmail(mail_to)) {
        err.mail_to = 'Mohon masukkan email yang valid';
      }
    }

    if (subject !== 0 && !subject) {
      err.subject = 'Mohon masukkan judul';
    }

    setErrors(err);

    if (err.mail_to || err.subject) {
      return false;
    }
    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const values = {
      mail_to: e.target.elements.mail_to.value,
      subject: e.target.elements.subject.value,
      body: stateToHTML(body.getCurrentContent()),
    };

    if (!checkValidity(values)) {
      return;
    }

    onSubmit(values, setSent);
  }

  if (sent) {
    return (
      <div className="d-flex flex-column">
        <CheckCircleFilled style={{ fontSize: 42, color: '#28a745' }} />
        <p className="text-center mt-2">
          {`Email telah berhasil dikirim ke ${sent}`}
        </p>
      </div>
    );
  }

  return (
    <Form id="generatedForm" ref={ref} onSubmit={handleSubmit} noValidate>
      <Form.Group controlId="mail_to">
        <Form.Label>Kepada</Form.Label>
        <Form.Control
          type="email"
          placeholder="Email"
          isInvalid={errors.mail_to}
          onChange={e => checkValidity({ mail_to: e.target.value })}
          defaultValue={data.to}
        />
        <Form.Control.Feedback type="invalid">
          {errors.mail_to}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="subject">
        <Form.Label>Judul</Form.Label>
        <Form.Control
          placeholder="Masukkan judul"
          isInvalid={errors.subject}
          onChange={e => checkValidity({ subject: e.target.value })}
          defaultValue={data.subject}
        />
        <Form.Control.Feedback type="invalid">
          {errors.subject}
        </Form.Control.Feedback>
      </Form.Group>
      <Editor
        editorState={body}
        onEditorStateChange={setBody}
        toolbar={{
          options: ['inline', 'blockType', 'fontFamily', 'list', 'emoji', 'image', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
          },
        }}
        toolbarStyle={{
          border: '1px solid #ced4da',
          borderRadius: '2px',
        }}
        editorStyle={{
          paddingLeft: '.75rem',
          paddingRight: '.75rem',
          border: '1px solid #ced4da',
          borderRadius: '2px',
        }}
      />
      <Button variant="link" className="d-flex align-items-center mt-2 px-0" href={url} target="_blank">
        <PaperClipOutlined className="mr-2" /> {`${ref_number}.pdf`}
      </Button>
    </Form>
  );
};

export default SendEmailForm;
