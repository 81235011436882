import React from 'react';
import moment from 'moment';
import {Button, Form} from 'react-bootstrap';
import {FormOutlined} from '@ant-design/icons';
import {validateEmail} from 'utils/helper';

class EditableText extends React.Component {
    state = {
        isEditing: false,
        isInvalid: false,
        invalidMessage: '',
    }

    onFinish = (e) => {
        if (this.state.isInvalid) {
            return;
        }

        let value = e.target.value;

        // if default exist and field is blank then replace with default
        if (this.props.default !== undefined && value === '') {
            value = this.props.default;
        }

        // if required and field is blank then set invalid
        if (this.props.required && value === '') {
            this.setState({isInvalid: true, invalidMessage: 'Tidak boleh kosong'});
            return;
        }

        this.props.setData(this.props.id, value);
        this.setState({isEditing: false});
    }

    handleKeyPress = (target) => {
        if ((target.charCode && target.charCode === 13) || (target.key && target.key === 'Enter')) {
            this.onFinish(target);
        }
    }

    validate = (e) => {
        if (this.props.maxLength !== undefined) {
            if (e.target.value.length > this.props.maxLength) {
                this.setState({
                    isInvalid: true,
                    invalidMessage: `Tidak boleh lebih dari ${this.props.maxLength} karakter`
                });
                return;
            }
        }

        if (e.target.value.length > 150) {
            this.setState({isInvalid: true, invalidMessage: 'Tidak boleh lebih dari 150 karakter'});
            return;
        }

        if (!(!this.props.required && e.target.value === '') && this.props.type !== 'number' && this.props.min !== undefined && e.target.value.length < this.props.min) {
            this.setState({isInvalid: true, invalidMessage: `Jumlah karakter minimal ${this.props.min}`});
            return;
        }

        if (!(!this.props.required && e.target.value === '') && this.props.type === 'number' && this.props.min !== undefined && e.target.value < this.props.min) {
            this.setState({isInvalid: true, invalidMessage: `Tidak boleh kurang dari ${this.props.min}`});
            return;
        }

        if (this.props.type === 'number' && this.props.max !== undefined && e.target.value > this.props.max) {
            this.setState({isInvalid: true, invalidMessage: `Tidak boleh lebih dari ${this.props.max}`});
            return;
        }


        if (this.props.type === 'date' && this.props.isSameOrAfter) {
            if (!moment(e.target.value).isSameOrAfter(moment(this.props.isSameOrAfter))) {
                this.setState({isInvalid: true, invalidMessage: 'Tidak boleh kurang dari tanggal'});
                return;
            }
        }

        if (!(!this.props.required && e.target.value === '') && this.props.type === 'email') {
            if (!validateEmail(e.target.value)) {
                this.setState({isInvalid: true, invalidMessage: 'Email tidak valid'});
                return;
            }
        }

        this.setState({isInvalid: false, invalidMessage: ''});
    }

    render() {
        const {isEditing, isInvalid, invalidMessage} = this.state;
        const {
            children,
            value,
            id,
            className,
            type = "text",
            setData,
            isSameOrAfter,
            isSameOrBefore,
            showRequired,
            required,
            maxLength,
            ...props
        } = this.props;

        return (
            <>
                {isEditing ? (
                    <>
                        <Form.Control
                            className={className}
                            autoFocus
                            type={type}
                            id={id}
                            defaultValue={value}
                            onChange={this.validate}
                            onBlur={this.onFinish}
                            onKeyPress={type === "textarea" ? () => {
                            } : this.handleKeyPress}
                            as={type === "textarea" ? type : undefined}
                            rows={type === "textarea" ? 3 : undefined}
                            isInvalid={isInvalid}
                            {...props}
                        />
                        {isInvalid && (
                            <Form.Control.Feedback type="invalid">
                                {invalidMessage}
                            </Form.Control.Feedback>
                        )}
                    </>
                ) : (
                    <>
                        <div className={className}>
                            {children}
                            <Button
                                variant="link"
                                className="px-1 py-0 text-warning"
                                style={{marginTop: -14}}
                                onClick={() => this.setState({isEditing: true})}
                            >
                                <FormOutlined/>
                            </Button>
                        </div>
                        {(required && showRequired && value === '') && (
                            <Form.Control.Feedback type="invalid" className="d-block mt-0">
                                Tidak boleh kosong
                            </Form.Control.Feedback>
                        )}
                    </>
                )}
            </>
        );
    }
}

export default EditableText;
