import axios from 'axios';

export const generateLayout = (id, data) => {
  return axios.post(`/invoice/generator/new/${id}`, data);
};

export const sendEmail = (id, data) => {
  return axios.post(`/invoice/generator/sendEmail/${id}`, data);
};

export const getTemplates = () => {
  return axios.get('/finance/layoutInvoice/templates');
}
