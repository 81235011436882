import React from "react";
import { Button, Modal } from "react-bootstrap";
import { CloseOutlined, SendOutlined } from "@ant-design/icons";

import styles from "./email-modal-warning.module.scss";

const EmailModalWarning = ({ show, close }) => {
  const onRegister = () => {
    window.open("https://kledo.com/daftar", "_blank", "noopener noreferrer");
  };

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      backdropClassName={styles.backdrop}
      className={styles.modal}
    >
      <Modal.Header className="px-4" closeButton>
        <Modal.Title>Kirim Email</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <p>
          Fasilitas pengiriman invoice melalui email hanya bisa dilakukan dari
          aplikasi kledo.
        </p>
      </Modal.Body>
      <Modal.Footer className="px-4">
        <Button
          variant="light"
          onClick={close}
          className="d-flex align-items-center"
        >
          <CloseOutlined className="mr-2" /> Tutup
        </Button>
        <Button
          type="button"
          form="generatedForm"
          variant="primary"
          className="d-flex align-items-center"
          onClick={onRegister}
        >
          Daftar Kledo Sekarang
          <SendOutlined className="ml-2" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailModalWarning;
