import React from 'react';
import { FormFile, Image } from 'react-bootstrap';
import { notify } from 'react-notify-bootstrap';
import { CloudUploadOutlined } from '@ant-design/icons';

class UploadImage extends React.Component {
  readFile = (e) => {
    const { setData, id } = this.props;

    if (e.target.files && e.target.files[0]) {
      // if file is more than 2MB, show message
      if (e.target.files[0].size > 2000000) {
        notify({ text: 'File maksimal 2MB', variant: 'warning' });
        return;
      }

      var FR = new FileReader();
      FR.addEventListener("load", function (e) {
        setData(id, e.target.result);
      });
      FR.readAsDataURL(e.target.files[0]);
    }
  }

  render() {
    const { id, image, margin, height, width, label, imgClass, imgPlaceholder = <></> } = this.props;

    return (
      <>
        {image ? (
          <Image src={image} height={height} width={width} className={`d-block ${imgClass}`} />
        ) : (
          imgPlaceholder
        )}
        <FormFile style={{ position: 'relative' }}>
          <FormFile.Label htmlFor={id}>
            <div className="d-flex align-items-center btn btn-sm btn-light border border-dark shadow-sm" style={{ margin }}>
              <CloudUploadOutlined className="mr-2" /> {label}
            </div>
          </FormFile.Label>
          <FormFile.Input
            id={id}
            size="sm"
            accept="image/jpeg, image/png"
            onChange={this.readFile}
            style={{ visibility: 'hidden', position: 'absolute' }}
          />
        </FormFile>
      </>
    );
  }
}

export default UploadImage;
