import _ from "lodash";

/**
 * melengkapi data input items, karena bbrp data harus dihitung terlebih dahulu
 */
export const parseInput = (inputs, isReturn = false) => {
  let values = { ...inputs };

  if (!values.additional_discount_percent) {
    values.additional_discount_percent = 0;
  }

  if (!values.shipping_cost) {
    values.shipping_cost = 0;
  }

  if (!values.down_payment) {
    values.down_payment = 0;
  }

  // Sudah dibayar
  if (!values.paid) {
    values.paid = 0;
  }

  if (!values.items) {
    values.items = [];
  } else {
    values.items = [...values.items];
  }

  // masukkan tax_id
  values = parseTaxId(values);

  // parse items untuk penyesuaian include tax
  values = parseItems(values);

  // Hitung subTotal
  values = parseSubTotal(values);

  // hitung additional_discount_amount
  values.additional_discount_amount = getAdditionalDiscountAmount(values);

  // Hitung taxable
  values = parseTaxable(values, isReturn);

  // Hitung tax
  if (values.include_tax) {
    values = parseTaxIncludeTax(values);
  } else {
    values = parseTaxNotIncludeTax(values);
  }

  // hitung aggregat tax (dikelompokkan berdasar tax_id)
  values = parseTaxAggregate(values, isReturn);
  // Rounding
  values = roundingValues(values);

  if (values.tax.length > 0) {
    const newTax = {};
    values.tax.forEach((tax) => {
      if (!newTax[tax.name]) {
        newTax[tax.name] = {};
        newTax[tax.name].id = tax.id;
      }
      newTax[tax.name].percent = tax.percent;
      newTax[tax.name].value = (newTax[tax.name].value ?? 0) + tax.value;
    });
    values.tax = Object.keys(newTax).map((taxName) => ({
      ...newTax[taxName],
      name: taxName,
    }));
  }

  return values;
};

/**
 * parse Items
 */
const parseItems = (inputs) => {
  const values = { ...inputs };
  values.items.forEach((item, index) => {
    const { price } = item;
    let { amount } = item;

    const newItem = {
      ...item,
      finance_account_id: item.finance_account_id,
      qty: item.qty,
      tax_id: item.tax_id,
      desc: item.desc,
      discount_percent: item.discount_percent || 0,
      price,
      amount,
    };
    if (values.include_tax) {
      newItem.price_after_tax = price;
      newItem.amount_after_tax = amount;
      newItem.tax_manual = 0;
    } else {
      newItem.price = price;
      newItem.amount = amount;
      newItem.tax_manual = item.tax_manual;
    }
    values.items[index] = newItem;
  });

  return values;
};

/**
 * hitung subtotal dari input
 * subtotal adalah jumlah semua amount, sebelum dipotong diskon dan pajak
 */
const parseSubTotal = (inputs) => {
  const values = { ...inputs };
  let allSubTotal = 0;
  let allDiscountAmount = 0;

  if (values.items) {
    values.items.forEach((item, i) => {
      const discountRate = item.discount_percent
        ? item.discount_percent / 100
        : 0;

      if (values.include_tax) {
        const subTotalPlusTax = item.amount_after_tax / (1 - discountRate);
        item.price_after_tax =
          item.qty > 0 ? Math.abs(subTotalPlusTax / item.qty) : 0;
        item.subTotal = getSubTotalIncludeTax(item, subTotalPlusTax, inputs);
      } else {
        item.subTotal = item.amount / (1 - discountRate);
        item.price = item.qty > 0 ? Math.abs(item.subTotal / item.qty) : 0;
      }

      item.discount_amount = item.subTotal * discountRate;
      allSubTotal += item.subTotal;
      allDiscountAmount += item.discount_amount;

      values.items[i] = item;
    });
  }

  values.subTotal = allSubTotal;
  values.discount_amount = allDiscountAmount;

  return values;
};

/**
 * hitung taxable dari input
 * jadinya nanti memudahkan saat hitung tax
 *
 * taxable adalah total amount, dikurangi diskon perline dan additional diskon
 */
const parseTaxable = (inputs, isReturn = false) => {
  const values = { ...inputs };
  let additionalDiscountTotal = 0;
  values.items.forEach((item, i) => {
    item.additional_discount_amount = 0;
    if (values.additional_discount_amount > 0) {
      let itemShare = 0;
      // Jika return hitung pakai cara ini
      if (isReturn) {
        const invoiceQty = item.qty_faktur || 0;
        const invoiceAdditionalDiscountAmount =
          item.additional_discount_amount_faktur || 0;
        itemShare = item.qty / invoiceQty;
        item.additional_discount_amount =
          itemShare * invoiceAdditionalDiscountAmount;
      } else {
        const totalShare = values.subTotal - values.discount_amount;
        itemShare = (item.subTotal - item.discount_amount) / totalShare;
        item.additional_discount_amount =
          itemShare * values.additional_discount_amount;
      }
      additionalDiscountTotal += item.additional_discount_amount;
    }

    item.taxable =
      item.subTotal - item.discount_amount - item.additional_discount_amount;
    values.items[i] = item;
  });
  if (isReturn) {
    values.additional_discount_amount = additionalDiscountTotal;
  }

  return values;
};

/**
 * hitung besaran tax dari input untuk include_tax=1
 */
const parseTaxIncludeTax = (inputs) => {
  const values = { ...inputs };
  values.items.forEach((item, i) => {
    if (item.tax_id && item.tax_id > 0) {
      const taxes = getTaxes(inputs);
      const findTax = taxes.find((row) => row.id === item.tax_id);
      if (findTax) {
        const taxRate = findTax.percent / 100;
        item.tax = item.taxable * taxRate;

        if (findTax.is_witholding) {
          item.tax *= -1;
          item.amount = item.amount_after_tax;
          item.price = item.price_after_tax;
        } else {
          item.amount = item.amount_after_tax - item.tax;
          item.price = Math.abs(item.amount / item.qty);
        }
      }
    } else {
      item.amount_after_tax = item.amount;
      item.price_after_tax = item.price;
      item.tax = 0;
    }

    values.items[i] = item;
  });

  return values;
};

/**
 * hitung besaran tax dari input untuk include_tax=0
 */
const parseTaxNotIncludeTax = (inputs) => {
  const values = { ...inputs };
  values.items.forEach((item, i) => {
    if (item.tax_id && item.tax_id > 0) {
      const taxes = getTaxes(inputs);
      const findTax = taxes.find((row) => row.id === item.tax_id);
      if (findTax) {
        const taxRate = findTax.percent / 100;

        if (findTax.is_allow_manual || item.tax_manual) {
          item.tax = item.tax_manual;
        } else {
          item.tax = item.taxable * taxRate;
        }

        if (findTax.is_witholding) {
          item.tax *= -1;
          item.amount_after_tax = item.amount;
          item.price_after_tax = item.price;
        } else {
          item.amount_after_tax = item.amount + item.tax;
          item.price_after_tax =
            item.qty > 0 ? Math.abs(item.amount_after_tax / item.qty) : 0;
        }
      }
    } else {
      item.amount_after_tax = item.amount;
      item.price_after_tax = item.price;
      item.tax = 0;
    }

    values.items[i] = item;
  });

  return values;
};

/**
 * kelompokkan tax berdasarkan tax_id nya dan di aggregate
 * hitung juga total kesuluruhan tax, keseluruhan amount dan amount_after tax
 */
const parseTaxAggregate = (inputs, isReturn = false) => {
  const values = { ...inputs };
  const allTax = [];
  let totalTax = 0;
  let totalAmount = 0;

  values.items.forEach((item, i) => {
    if (item.tax_id && item.tax_id >= 1) {
      const taxId = item.tax_id;
      const taxes = getTaxes(inputs);
      const findTax = taxes.find((row) => row.id === taxId);
      if (findTax) {
        const indexSelected = _.findIndex(allTax, { id: findTax.id });
        if (indexSelected !== -1) {
          allTax[indexSelected].value += item.tax;
        } else {
          allTax.push({
            id: findTax.id,
            name: findTax.name,
            value: item.tax,
            percent: findTax.percent,
          });
        }
        totalTax += item.tax;
      }
    }

    totalAmount += item.amount;
    values.items[i].tax = item.tax;
  });

  values.tax = allTax;
  values.total_tax = totalTax;
  values.amount_after_tax =
    values.subTotal -
    values.discount_amount -
    values.additional_discount_amount +
    values.total_tax;
  // Jika tidak return di tambah shipping cost
  if (!isReturn) {
    values.amount_after_tax += values.shipping_cost;
  }
  values.amount = totalAmount;
  values.due = values.amount_after_tax - values.paid - values.down_payment;
  // Jika return di tambah shipping cost
  if (isReturn) {
    values.due += values.shipping_cost;
  }

  return values;
};

/**
 * subtotal untuk include tax = 1
 * beda dg includetax=0 karena perlu dikurangi dengan pajak
 */
const getSubTotalIncludeTax = (item, subTotalPlusTax, inputs) => {
  let subTotal = subTotalPlusTax;

  if (item.tax_id && item.tax_id > 0) {
    const taxes = getTaxes(inputs);
    const findTax = taxes.find((row) => row.id === item.tax_id);
    if (findTax) {
      const taxRate = findTax.percent / 100;
      if (!findTax.is_witholding) {
        subTotal = subTotalPlusTax / (1 + taxRate);
      }
    }
  }

  return subTotal;
};

/**
 * hitung additional_discount_amount
 * jika inputnya memasukkan additional_discount dalam percent
 * makan amount dari additional_discount dihitung disini
 *
 * jika menggunakan percent, cara hitungnya
 * subtotal dikurang diskon dikali additional_discount percent rate
 */
const getAdditionalDiscountAmount = (values) => {
  let additionalDiscountAmount = 0;
  if (values.additional_discount_amount) {
    additionalDiscountAmount = values.additional_discount_amount;
  } else if (values.additional_discount_percent > 0) {
    const discountRate = values.additional_discount_percent / 100;
    additionalDiscountAmount =
      discountRate * (values.subTotal - values.discount_amount);
  }

  return additionalDiscountAmount;
};

const round = (value) => _.round(value, 2);

const roundingValues = (inputs) => {
  const values = { ...inputs };
  if (values.additional_discount_amount) {
    values.additional_discount_amount = round(
      values.additional_discount_amount
    );
  }
  if (values.subTotal) {
    values.subTotal = round(values.subTotal);
  }
  if (values.discount_amount) {
    values.discount_amount = round(values.discount_amount);
  }
  if (values.additional_discount_amount_item_total) {
    values.additional_discount_amount_item_total = round(
      values.additional_discount_amount_item_total
    );
  }
  if (values.due) {
    values.due = round(values.due);
  }
  if (values.total_tax) {
    values.total_tax = round(values.total_tax);
  }
  if (values.amount_after_tax) {
    values.amount_after_tax = round(values.amount_after_tax);
  }
  if (values.amount) {
    values.amount = round(values.amount);
  }
  if (values.tax.length > 0) {
    values.tax = values.tax.map((row) => {
      return {
        ...row,
        value: round(row.value),
      };
    });
  }
  if (values.items.length > 0) {
    values.items = values.items.map((row) => {
      const item = { ...row };
      if (item.amount) {
        item.amount = round(row.amount);
      }
      if (item.price) {
        item.price = round(row.price);
      }
      if (item.amount_after_tax) {
        item.amount_after_tax = round(row.amount_after_tax);
      }
      if (item.price_after_tax) {
        item.price_after_tax = round(row.price_after_tax);
      }
      if (item.tax) {
        item.tax = round(row.tax);
      }
      if (item.subTotal) {
        item.subTotal = round(row.subTotal);
      }
      if (item.discount_amount) {
        item.discount_amount = round(row.discount_amount);
      }
      if (item.additional_discount_amount) {
        item.additional_discount_amount = round(row.additional_discount_amount);
      }
      if (item.taxable) {
        item.taxable = round(row.taxable);
      }

      return item;
    });
  }

  return values;
};

/**
 * Ambil taxes
 */
const getTaxes = (inputs) => {
  const { items } = inputs;
  let taxes = [];

  items.forEach((item, i) => {
    if (item.tax_percent) {
      const findTax = taxes.find((row) => row.id === item.tax_id);
      if (!findTax) {
        taxes.push({
          id: i + 1,
          name: item.tax_title,
          percent: item.tax_percent,
        });
        item.tax_id = i + 1;
      }
    }
  });

  return taxes || [];
};

/**
 * Masukkan tax_id ke item
 */
const parseTaxId = (inputs) => {
  const values = { ...inputs };
  const { items } = values;
  let taxes = [];

  items.forEach((item, i) => {
    if (item.tax_percent) {
      const findTax = taxes.find((row) => row.name === item.tax_title);
      if (!findTax) {
        taxes.push({
          id: i + 1,
          name: item.tax_title,
          percent: item.tax_percent,
        });
        item.tax_id = i + 1;
      }
    }
  });

  return values;
};
