import React from "react";
import moment from "moment";
import _ from "lodash";
import { parse } from "query-string";
import {
  DownloadOutlined,
  LoadingOutlined,
  PicLeftOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import { currency } from "utils/formatter";
import { generateLayout, getTemplates } from "utils/apis/layoutGenerator";
import { parseInput } from "components/LayoutGenerator/parseTransactionInput";
import UploadImage from "components/LayoutGenerator/UploadImage";
import EditableText from "components/LayoutGenerator/EditableText";
import TemplateModal from "components/LayoutGenerator/TemplateModal";
import InvoiceModal from "components/LayoutGenerator/InvoiceModal";
import Item from "components/LayoutGenerator/Item";

class Main extends React.Component {
  localStorage = window.localStorage;
  state = {
    data: {
      ref_number: "INV/00001",
      trans_date: moment().format("YYYY-MM-DD"),
      due_date: moment().add(1, "month").format("YYYY-MM-DD"),
      contact_name: "",
      contact_address: "",
      contact_phone: "",
      contact_email: "",
      company_name: "",
      company_address: "",
      company_phone: "",
      company_email: "",
      items: [
        {
          product_name: "Nama Produk",
          description: "Deskripsi produk",
          qty: 1,
          unit_name: "Pcs",
          price: 0,
          amount: 0,
          discount_percent: 0,
          tax_percent: 10,
          tax_manual: 0,
          tax_title: "PPN",
        },
      ],
      message: "",
      company_logo: "",
      signature_name: "PT. Kledo Berhati Nyaman",
      signature_dept: "Finance Dept",
      invoice_signature_url: "",
      invoice_lang_product: "Produk",
      invoice_lang_qty: "Kuantitas",
      invoice_lang_unit: "Satuan",
      invoice_lang_desc: "Deskripsi",
      invoice_lang_amount: "Jumlah",
      invoice_lang_signature_header: "Dengan Hormat,",
      invoice_lang_message: "Pesan",
      trans_type_title: "Invoice",
      include_tax: 0,
      invoice_show_discount: 1,
      invoice_show_tax: 1,
      invoice_show_unit: 0,
    },
    defaultProduct: {
      product_name: "Nama Produk",
      description: "Deskripsi produk",
      qty: 1,
      unit_name: "Pcs",
      price: 0,
      amount: 0,
      discount_percent: 0,
      tax_percent: 10,
      tax_manual: 0,
      tax_title: "PPN",
    },
    currentTemplate: {
      cat_id: 2,
      id: 1,
      img_url:
        "https://kledo-live-img.s3-ap-southeast-1.amazonaws.com/invoicelayout/1.jpg",
      name: "1",
    },
    templates: {
      categories: {},
      templates: [],
    },
    isLoading: false,
    showRequired: false,
    isLoadingTemplate: true,
    showTemplateModal: false,
    showInvoiceModal: false,
    isLoadingSendModal: false,
    generated: {},
    width: window.innerWidth,
  };

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const { template_id } = parse(search);

    if (template_id) {
      this.getTemplateData().then(() => {
        const { templates } = this.state.templates;
        const template = templates.filter(
          (e) => e.id.toString() === template_id
        );
        if (template.length > 0) {
          this.setState({ currentTemplate: template[0] });
        }
      });
    }

    const prevData = this.localStorage.getItem("invoice-data");
    if (prevData) {
      this.setState({ data: JSON.parse(prevData) });
    }

    window.addEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.state.data, prevState.data)) {
      this.updateCookie();
    }

    if (prevState.data.invoice_show_tax !== this.state.data.invoice_show_tax) {
      if (this.state.data.invoice_show_tax === 1) {
        this.setDefaultTax();
      } else {
        this.removeTax();
      }
    }

    if (
      prevState.data.invoice_show_discount !==
      this.state.data.invoice_show_discount
    ) {
      this.removeDiscount();
    }
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  updateCookie = () => {
    this.localStorage.setItem("invoice-data", JSON.stringify(this.state.data));
  };

  setDefaultTax = () => {
    let newItems = this.state.data.items.map((item) => {
      let discountAmount = 0;
      let priceAfterDiscount = item.price;
      if (item.discount_percent > 0) {
        discountAmount = (item.price * item.discount_percent) / 100;
        priceAfterDiscount = item.price - discountAmount;
      }
      item.tax_percent = 10;
      item.tax_title = "PPN";
      item.amount = priceAfterDiscount * item.qty;
      return item;
    });

    this.setState(
      {
        data: {
          ...this.state.data,
          items: [...newItems],
        },
      },
      this.updateCookie
    );
  };

  removeTax = () => {
    let newItems = this.state.data.items.map((item) => {
      let discountAmount = 0;
      let priceAfterDiscount = item.price;
      if (item.discount_percent > 0) {
        discountAmount = (item.price * item.discount_percent) / 100;
        priceAfterDiscount = item.price - discountAmount;
      }
      item.tax_percent = 0;
      item.tax_title = "";
      item.amount = priceAfterDiscount * item.qty;
      return item;
    });

    this.setState(
      {
        data: {
          ...this.state.data,
          items: [...newItems],
        },
      },
      this.updateCookie
    );
  };

  removeDiscount = () => {
    let newItems = this.state.data.items.map((item) => {
      let discountAmount = 0;
      let priceAfterDiscount = item.price;
      if (item.discount_percent > 0 && this.state.data.invoice_show_discount) {
        discountAmount = (item.price * item.discount_percent) / 100;
        priceAfterDiscount = item.price - discountAmount;
      }
      item.discount_percent = 0;
      item.amount = priceAfterDiscount * item.qty;
      return item;
    });

    this.setState(
      {
        data: {
          ...this.state.data,
          items: [...newItems],
        },
      },
      this.updateCookie
    );
  };

  setData = (id, val) => {
    this.setState(
      { data: { ...this.state.data, [id]: val } },
      this.updateCookie
    );
  };

  setItem = (id, val) => {
    let newItems = this.state.data.items.map((item, i) => {
      if (i === id.i) {
        item[id.name] = val;
        let discountAmount = 0;
        let priceAfterDiscount = item.price;
        if (item.discount_percent > 0) {
          discountAmount = (item.price * item.discount_percent) / 100;
          priceAfterDiscount = item.price - discountAmount;
        }

        if (id.name === "price") {
          item.price = val;
          item.amount = priceAfterDiscount * item.qty;
        } else if (id.name === "qty") {
          item.qty = val;
          item.amount = val * priceAfterDiscount;
        } else if (id.name === "amount") {
          item.amount = val;
          item.price = val / (item.qty * (1 - item.discount_percent / 100));
        } else if (id.name === "discount_percent") {
          item.discount_percent = val;
          item.amount = priceAfterDiscount * item.qty;
        } else if (id.name === "tax_percent") {
          item.tax_percent = val;
          item.amount = priceAfterDiscount * item.qty;
        }
      }
      return item;
    });

    this.setState(
      {
        data: {
          ...this.state.data,
          items: [...newItems],
        },
      },
      this.updateCookie
    );
  };

  removeItem = (i) => {
    let newItems = this.state.data.items.filter((item, idx) => idx !== i);

    this.setState(
      {
        data: {
          ...this.state.data,
          items: [...newItems],
        },
      },
      this.updateCookie
    );
  };

  addItem = () => {
    let newItems = this.state.data.items;
    newItems.push({ ...this.state.defaultProduct });

    this.setState(
      {
        data: {
          ...this.state.data,
          items: [...newItems],
        },
      },
      this.updateCookie
    );
  };

  renderItems = () => {
    const { data, width } = this.state;

    return (
      <Item
        data={data}
        width={width}
        setItem={this.setItem}
        removeItem={this.removeItem}
        setData={this.setData}
      />
    );
  };

  isValid = () => {
    const { data } = this.state;

    if (data.company_name.length < 2 || data.contact_name.length < 2) {
      return false;
    }
    if (data.company_email === "" || data.company_phone === "") {
      return false;
    }

    if (data.contact_name.length < 2) {
      return false;
    }

    return true;
  };

  onDownload = async () => {
    if (this.isValid()) {
      try {
        this.setState({ isLoading: true, showRequired: false });
        const response = await generateLayout(
          this.state.currentTemplate.id,
          this.state.data
        );
        this.setState({
          isLoading: false,
          showInvoiceModal: true,
          generated: response.data,
        });
      } catch (e) {
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ showRequired: true });
    }
  };

  closeInvoiceModal = () => {
    this.setState({ showInvoiceModal: false });
  };

  closeTemplateModal = () => {
    this.setState({ showTemplateModal: false });
  };

  getTemplateData = async () => {
    this.setState({ isLoadingTemplate: true });
    try {
      const response = await getTemplates();
      if (response.data.success) {
        this.setState({
          templates: response.data.data,
          isLoadingTemplate: false,
        });
      } else {
        this.setState({ isLoadingTemplate: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ isLoadingTemplate: false });
    }
  };

  showTemplateModal = () => {
    this.setState({ showTemplateModal: true });
    this.getTemplateData();
  };

  setCurrentTemplate = (template) => {
    this.setState({ currentTemplate: template, showTemplateModal: false });
  };

  onSubmitWA = ({ phone, message }) => {
    window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${message}`);
  };

  render() {
    const {
      data,
      isLoading,
      showRequired,
      currentTemplate,
      showTemplateModal,
      isLoadingTemplate,
      templates,
      showInvoiceModal,
      generated,
      isLoadingSendModal,
    } = this.state;
    const calculate = parseInput(data);

    const buttonstyle = {
      textAlign: "center",
      width: "90%",
      maxWidth: "450px",
      fontSize: "42px",
    };

    return (
      <>
        <Container className="my-3 p-4">
          <Row>
            <Col md={6}>
              <UploadImage
                height={60}
                width="auto"
                margin={"1rem 0 0 0"}
                label="Upload Logo"
                image={data.company_logo}
                id="company_logo"
                setData={this.setData}
                imgPlaceholder={
                  <h2 className="text-primary font-weight-bold">
                    {data.company_name || "Nama Perusahaan Anda"}
                  </h2>
                }
              />
            </Col>
            <Col md={6} className="text-right">
              <EditableText
                className="mb-2"
                setData={this.setData}
                value={data.trans_type_title}
                id="trans_type_title"
              >
                <h2 className="text-primary font-weight-bold d-inline-block">
                  {data.trans_type_title || "Invoice"}
                </h2>
              </EditableText>
              <Row>
                <Col span={8}>
                  <small className="mb-0 mt-1">Referensi</small>
                </Col>
                <Col span={4}>
                  <EditableText
                    setData={this.setData}
                    value={data.ref_number}
                    id="ref_number"
                  >
                    <small className="d-inline-block">{data.ref_number}</small>
                  </EditableText>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <small className="mb-0 mt-1">Tanggal</small>
                </Col>
                <Col span={4}>
                  <EditableText
                    type="date"
                    isSameOrBefore={data.due_date}
                    setData={this.setData}
                    value={data.trans_date}
                    id="trans_date"
                  >
                    <small className="d-inline-block">
                      {moment(data.trans_date).format("DD/MM/YYYY")}
                    </small>
                  </EditableText>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <small className="mb-0 mt-1">Tgl. Jatuh Tempo</small>
                </Col>
                <Col span={4}>
                  <EditableText
                    type="date"
                    isSameOrAfter={data.trans_date}
                    setData={this.setData}
                    value={data.due_date}
                    id="due_date"
                  >
                    <small className="d-inline-block">
                      {moment(data.due_date).format("DD/MM/YYYY")}
                    </small>
                  </EditableText>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <small>
                <strong>Informasi Perusahaan</strong>
              </small>
              <hr className="border-dark mt-2" style={{ borderWidth: 2 }} />
              <EditableText
                required
                min={2}
                className="mb-1"
                setData={this.setData}
                value={data.company_name}
                id="company_name"
                showRequired={showRequired}
              >
                <small className="text-primary d-inline-block">
                  <strong>{data.company_name || "Nama Perusahaan Anda"}</strong>
                </small>
              </EditableText>
              <EditableText
                type="textarea"
                setData={this.setData}
                value={data.company_address}
                id="company_address"
              >
                <small
                  className="d-inline-block"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {data.company_address || "Alamat Perusahaan Anda"}
                </small>
              </EditableText>
              <EditableText
                required
                min={4}
                type="tel"
                setData={this.setData}
                value={data.company_phone}
                id="company_phone"
                showRequired={showRequired}
              >
                <small className="d-inline-block">
                  Telp: {data.company_phone || "No Telp Perusahaan Anda"}
                </small>
              </EditableText>
              <EditableText
                required
                type="email"
                setData={this.setData}
                value={data.company_email}
                id="company_email"
                showRequired={showRequired}
              >
                <small className="d-inline-block">
                  Email: {data.company_email || "email@perusahaan.anda"}
                </small>
              </EditableText>
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <small>
                <strong>Tagihan Kepada</strong>
              </small>
              <hr className="border-dark mt-2" style={{ borderWidth: 2 }} />
              <EditableText
                required
                min={2}
                className="mb-1"
                setData={this.setData}
                value={data.contact_name}
                id="contact_name"
                showRequired={showRequired}
              >
                <small className="text-primary d-inline-block">
                  <strong>{data.contact_name || "Nama Pelanggan"}</strong>
                </small>
              </EditableText>
              <EditableText
                type="textarea"
                setData={this.setData}
                value={data.contact_address}
                id="contact_address"
              >
                <small
                  className="d-inline-block"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {data.contact_address || "Alamat Pelanggan"}
                </small>
              </EditableText>
              <EditableText
                min={4}
                type="tel"
                setData={this.setData}
                value={data.contact_phone}
                id="contact_phone"
              >
                <small className="d-inline-block">
                  Telp: {data.contact_phone || "No Telp Pelanggan"}
                </small>
              </EditableText>
              <EditableText
                type="email"
                setData={this.setData}
                value={data.contact_email}
                id="contact_email"
              >
                <small className="d-inline-block">
                  Email: {data.contact_email || "email@perusahaan.pelanggan"}
                </small>
              </EditableText>
            </Col>
          </Row>
          <Table className="mt-5" borderless responsive>
            <thead className="items-header">
              <tr style={{ backgroundColor: "#314057" }}>
                <td>
                  <EditableText
                    setData={this.setData}
                    value={data.invoice_lang_product}
                    id="invoice_lang_product"
                  >
                    <small className="text-white d-inline-block">
                      <strong>{data.invoice_lang_product || "Produk"}</strong>
                    </small>
                  </EditableText>
                </td>
                <td>
                  <EditableText
                    setData={this.setData}
                    value={data.invoice_lang_desc}
                    id="invoice_lang_desc"
                  >
                    <small className="text-white d-inline-block">
                      <strong>{data.invoice_lang_desc || "Deskripsi"}</strong>
                    </small>
                  </EditableText>
                </td>
                <td className="text-right">
                  <EditableText
                    setData={this.setData}
                    value={data.invoice_lang_qty}
                    id="invoice_lang_qty"
                  >
                    <small className="text-white d-inline-block">
                      <strong>{data.invoice_lang_qty || "Kuantitas"}</strong>
                    </small>
                  </EditableText>
                </td>
                {data.invoice_show_unit === 1 && (
                  <td className="text-right">
                    <EditableText
                      setData={this.setData}
                      value={data.invoice_lang_unit}
                      id="invoice_lang_unit"
                    >
                      <small className="text-white d-inline-block">
                        <strong>{data.invoice_lang_unit || "Satuan"}</strong>
                      </small>
                    </EditableText>
                  </td>
                )}
                <td className="text-right">
                  <small className="text-white d-inline-block">
                    <strong>Harga</strong>
                  </small>
                </td>
                {data.invoice_show_discount === 1 && (
                  <td className="text-right">
                    <small className="text-white d-inline-block">
                      <strong>Diskon</strong>
                    </small>
                  </td>
                )}
                {data.invoice_show_tax === 1 && (
                  <td className="text-right">
                    <small className="text-white d-inline-block">
                      <strong>Pajak</strong>
                    </small>
                  </td>
                )}
                <td className="text-right">
                  <EditableText
                    setData={this.setData}
                    value={data.invoice_lang_amount}
                    id="invoice_lang_amount"
                  >
                    <small className="text-white d-inline-block">
                      <strong>{data.invoice_lang_amount || "Jumlah"}</strong>
                    </small>
                  </EditableText>
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>{this.renderItems()}</tbody>
          </Table>
          <Button
            size="sm"
            variant="outline-primary"
            className="d-flex align-items-center mx-auto"
            onClick={this.addItem}
          >
            <PlusOutlined className="mr-2" /> Tambah Produk
          </Button>
          <Row className="mt-4">
            <Col md={6}>
              <EditableText
                maxLength={50}
                setData={this.setData}
                value={data.invoice_lang_message}
                id="invoice_lang_message"
              >
                <small>
                  <strong className="text-primary">
                    {data.invoice_lang_message || "Pesan"}
                  </strong>
                </small>
              </EditableText>
              <hr className="border-dark mt-2" style={{ borderWidth: 2 }} />
              <EditableText
                type="textarea"
                className="d-flex"
                setData={this.setData}
                value={data.message}
                id="message"
              >
                <div
                  className="border border-dark w-100 px-2"
                  style={{ height: 90 }}
                >
                  <small style={{ whiteSpace: "pre-line" }}>
                    {data.message}
                  </small>
                </div>
              </EditableText>
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Row className="text-right mb-2">
                <Col>
                  <small>
                    <strong>Subtotal</strong>
                  </small>
                </Col>
                <Col>
                  <small>
                    <strong>{currency(calculate.subTotal)}</strong>
                  </small>
                </Col>
              </Row>
              {data.invoice_show_discount === 1 && (
                <Row className="text-right mb-2">
                  <Col>
                    <small>
                      <strong>Total Diskon</strong>
                    </small>
                  </Col>
                  <Col>
                    <small>
                      <strong>({currency(calculate.discount_amount)})</strong>
                    </small>
                  </Col>
                </Row>
              )}
              {calculate.tax.map((tax) => {
                return (
                  <Row className="text-right mb-2" key={tax.name}>
                    <Col>
                      <small>
                        <strong>{tax.name}</strong>
                      </small>
                    </Col>
                    <Col>
                      <small>
                        <strong>{currency(tax.value)}</strong>
                      </small>
                    </Col>
                  </Row>
                );
              })}
              <Row className="text-right mb-2">
                <Col>
                  <h4>
                    <b>
                      <u>Total</u>
                    </b>
                  </h4>
                </Col>
                <Col>
                  <h4>
                    <b>
                      <u>{currency(calculate.amount_after_tax)}</u>
                    </b>
                  </h4>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col />
            <Col sm={3} className="text-center">
              <EditableText
                setData={this.setData}
                value={data.invoice_lang_signature_header}
                id="invoice_lang_signature_header"
              >
                <small>
                  {data.invoice_lang_signature_header || "Dengan Hormat,"}
                </small>
              </EditableText>
              <UploadImage
                height={60}
                imgClass="mx-auto mt-3"
                width="auto"
                margin={"1rem 0 0 0"}
                label="Upload Signature"
                image={data.invoice_signature_url}
                id="invoice_signature_url"
                setData={this.setData}
                imgPlaceholder={<div style={{ marginTop: 70 }}></div>}
              />
              <EditableText
                setData={this.setData}
                value={data.signature_name}
                id="signature_name"
              >
                <small>
                  <u>{data.signature_name}</u>
                </small>
              </EditableText>
              <EditableText
                setData={this.setData}
                value={data.signature_dept}
                id="signature_dept"
              >
                <small>{data.signature_dept}</small>
              </EditableText>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6} lg={3}>
              <Form.Check
                custom
                type="switch"
                id="discount-switch"
                label="Tampilkan diskon"
                checked={data.invoice_show_discount === 1}
                onChange={(e) =>
                  this.setData(
                    "invoice_show_discount",
                    e.target.checked ? 1 : 0
                  )
                }
                className="form-control-sm"
              />
              <Form.Check
                custom
                type="switch"
                id="tax-switch"
                label="Tampilkan pajak"
                checked={data.invoice_show_tax === 1}
                onChange={(e) =>
                  this.setData("invoice_show_tax", e.target.checked ? 1 : 0)
                }
                className="form-control-sm"
              />
              <Form.Check
                custom
                type="switch"
                id="unit-switch"
                label="Tampilkan satuan"
                checked={data.invoice_show_unit === 1}
                onChange={(e) =>
                  this.setData("invoice_show_unit", e.target.checked ? 1 : 0)
                }
                className="form-control-sm"
              />
            </Col>
            <Col md={6} lg={3} className="text-center">
              <small>
                <b>Template</b>
              </small>
              <a
                href={currentTemplate.img_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={currentTemplate.img_url} className="template-img" />
              </a>
              <Button
                variant="primary"
                size="sm"
                className="d-flex align-items-center mx-auto"
                onClick={this.showTemplateModal}
              >
                <PicLeftOutlined className="mr-2" /> Ganti Template
              </Button>
            </Col>
          </Row>
        </Container>
        <hr />
        <Button
          variant="success"
          size="lg"
          className={`mx-auto ${
            showRequired ? "mt-4 mb-2" : "my-4"
          } d-flex align-items-center`}
          onClick={this.onDownload}
          disabled={isLoading}
          style={buttonstyle}
        >
          {isLoading ? (
            <LoadingOutlined className="mr-2" />
          ) : (
            <DownloadOutlined className="mr-2" />
          )}
          &nbsp;Download Invoice
        </Button>
        <Form.Control.Feedback
          type="invalid"
          className={showRequired ? "d-block mt-0 text-center mb-4" : ""}
        >
          Masukkan semua data yang harus diisi
        </Form.Control.Feedback>
        <TemplateModal
          show={showTemplateModal}
          close={this.closeTemplateModal}
          templates={templates}
          isLoading={isLoadingTemplate}
          currentTemplate={currentTemplate}
          setCurrentTemplate={this.setCurrentTemplate}
        />
        <InvoiceModal
          show={showInvoiceModal}
          close={this.closeInvoiceModal}
          email={data.company_email}
          ref_number={data.ref_number}
          data={generated}
          onSubmitWA={this.onSubmitWA}
          isLoading={isLoadingSendModal}
        />
      </>
    );
  }
}

export default Main;
