import React, { useState } from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import {
  CloseOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  SendOutlined,
} from "@ant-design/icons";

import SendWhatsappForm from "./SendWhatsappForm";
import SendEmailForm from "./SendEmailForm";
import EmailModalWarning from "./EmailModalWarning";

const InvoiceModal = ({
  show,
  close,
  email,
  ref_number,
  data,
  onSubmitWA,
  isLoading,
}) => {
  const [tab, setTab] = useState("pdf");
  const [sent, setSent] = useState(false);
  const [showEmailModalWarning, setShowEmailModalWarning] = useState(false);

  const handleClose = () => {
    setTab("pdf");
    setSent(false);
    close();
  };

  const handleSubmitEmail = () => setShowEmailModalWarning(true);

  const handleChangeTab = (tab) => {
    setSent(false);
    setTab(tab);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="px-4" closeButton>
          <Modal.Title>Hore!!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <p>
            {`Invoice berhasil dibuat dan telah dikirimkan ke alamat email mu (${
              email || ""
            })`}
          </p>
          <ButtonGroup size="sm" aria-label="Tab" className="mb-4">
            <Button
              variant={tab === "pdf" ? "primary" : "outline-primary"}
              onClick={() => handleChangeTab("pdf")}
            >
              Download PDF
            </Button>
            <Button
              variant={tab === "email" ? "primary" : "outline-primary"}
              onClick={() => handleChangeTab("email")}
            >
              Kirim Email
            </Button>
            <Button
              variant={tab === "wa" ? "primary" : "outline-primary"}
              onClick={() => handleChangeTab("wa")}
            >
              Whatsapp
            </Button>
          </ButtonGroup>
          <br />
          {tab === "pdf" && (
            <Button
              variant="link"
              className="d-flex align-items-center"
              href={data.url}
              target="_blank"
            >
              <FilePdfOutlined className="mr-2" style={{ fontSize: 22 }} />{" "}
              {`${ref_number}.pdf`}
            </Button>
          )}
          {tab === "email" && (
            <SendEmailForm
              url={data.url}
              ref_number={ref_number}
              onSubmit={handleSubmitEmail}
              data={data.email}
            />
          )}
          {tab === "wa" && (
            <SendWhatsappForm onSubmit={onSubmitWA} data={data.whatsapp} />
          )}
        </Modal.Body>
        <Modal.Footer className="px-4">
          <Button
            variant="light"
            onClick={handleClose}
            className="d-flex align-items-center"
          >
            <CloseOutlined className="mr-2" /> Tutup
          </Button>
          {tab !== "pdf" && !sent && (
            <Button
              type="submit"
              form="generatedForm"
              variant="primary"
              className="d-flex align-items-center"
            >
              {isLoading ? (
                <LoadingOutlined className="mr-2" />
              ) : (
                <SendOutlined className="mr-2" />
              )}
              Kirim
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <EmailModalWarning
        show={showEmailModalWarning}
        close={() => setShowEmailModalWarning(false)}
      />
    </>
  );
};

export default InvoiceModal;
