import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const SendWhatsappForm = ({ onSubmit, ref, data }) => {
  const [errors, setErrors] = useState({});

  const checkValidity = ({ phone = 0, message = 0 }) => {
    let err = {};

    if (phone !== 0) {
      if (!phone) {
        err.phone = 'Mohon masukkan nomor HP';
      }
      if (phone && !phone.match(/^\d+$/)) {
        err.phone = 'Mohon masukkan angka';
      } else if (phone && phone.length < 7) {
        err.phone = 'Minimal 7 angka';
      } else if (phone && phone.charAt(0) !== '0' && (phone.charAt(0) !== '6' || phone.charAt(1) !== '2')) {
        err.phone = 'Nomor HP harus diawali 0 atau 62';
      }
    }

    if (message !== 0 && !message) {
      err.message = 'Mohon masukkan pesan';
    }

    setErrors(err);

    if (err.phone || err.message) {
      return false;
    }
    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const values = {
      phone: e.target.elements.phone.value,
      message: e.target.elements.message.value,
    };

    if (!checkValidity(values)) {
      return;
    }

    onSubmit(values);
  }

  const onPhoneFocus = (e) => {
    if (e.target.value && e.target.value.charAt(0) === '0') {
      e.target.value = e.target.value.replace('0', '62');
    }
  }

  return (
    <Form id="generatedForm" ref={ref} onSubmit={handleSubmit} noValidate>
      <Form.Group controlId="phone">
        <Form.Label>No. HP</Form.Label>
        <Form.Control
          type="tel"
          placeholder="Masukkan nomor HP"
          isInvalid={errors.phone}
          onChange={e => checkValidity({ phone: e.target.value })}
          defaultValue={data.phone}
          onFocus={onPhoneFocus}
        />
        <Form.Control.Feedback type="invalid">
          {errors.phone}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="message">
        <Form.Label>Pesan</Form.Label>
        <Form.Control
          as="textarea"
          rows={4}
          isInvalid={errors.message}
          onChange={e => checkValidity({ message: e.target.value })}
          defaultValue={data.body}
        />
        <Form.Control.Feedback type="invalid">
          {errors.message}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

export default SendWhatsappForm;
