import currencyFormatter from 'currency-formatter'

export const currency = (val, unformat = false, symbolNon = false, ifNull = false, precision = 2) => {
  const options = {
    property_currency_format: "2.,",
  }
  
  let symbol = options.property_currency_symbol ? `${options.property_currency_symbol} ` : 'Rp ';
  if (symbolNon) {
    symbol = ''
  }

  let format = { symbol, decimal: '.', precision: 0 };
  if (options.property_currency_format === '0.,') {
    format = { symbol, thousand: '.', precision: 0 };
  } else if (options.property_currency_format === '2-.') {
    format = { symbol, thousand: '', decimal: '.', precision };
  } else if (options.property_currency_format === '2-,') {
    format = { symbol, thousand: '', decimal: ',', precision };
  } else if (options.property_currency_format === '2.,') {
    format = { symbol, thousand: '.', decimal: ',', precision };
  } else if (options.property_currency_format === '2,.') {
    format = { symbol, thousand: ',', decimal: '.', precision };
  } else if (options.property_currency_format === '2 .') {
    format = { symbol, thousand: ' ', decimal: '.', precision };
  } else if (options.property_currency_format === '2 ,') {
    format = { symbol, thousand: ' ', decimal: ',', precision };
  }
  let result = 0
  if (unformat) {
    val = `${val || ''}`.split(format.thousand || '').join('')
    result = currencyFormatter.unformat(val || 0, format);
  } else {
    result = currencyFormatter.format(val || 0, format);
  }

  if (ifNull && result === '0') {
    return '-'
  }
  if (unformat === 'format') {
    return format
  }
  return result
}

export const qtyToCurrency = (value) => {
  const qty = parseFloat(value);
  const result = (qty - Math.floor(qty)) !== 0;
  if (result) {
    return currency(qty, false, true);
  }

  return currency(qty, false, true, false, 0);
}
