import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Button } from 'react-bootstrap';
import Notification from 'react-notify-bootstrap'
import Main from 'containers/Main';

class App extends React.Component {
  errorComponent = () => {
    return (
      <React.Fragment>
        <h4>You have encountered an error</h4>
        <Button
          type="primary"
          onClick={() => {
            window.location.reload();
          }}
        >
          Click here to reload
        </Button>
      </React.Fragment>
    );
  }

  render() {
    return (
      <BrowserRouter>
        <Sentry.ErrorBoundary
          fallback={this.errorComponent()}
          showDialog
        >
          <Switch>
            <Route path="/" exact component={Main} />
            <Route path="**" exact component={Main} />
          </Switch>
          <Notification />
        </Sentry.ErrorBoundary>
      </BrowserRouter>
    );
  }
}

export default App;
